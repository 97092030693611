<template>
  <div class="b-card-effect" @click="toPage">
    <div :class="`b-card-effect--icon color--${color}`">
      <i :class="icon"></i>
    </div>
    <div class="b-card-effect__content">
      <div class="b-card-effect__content--description">{{ value }}</div>
      <div class="b-card-effect__content--title">{{ description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      require: false
    },
    icon: {
      type: String,
      require: true
    },
    description: {
      type: String,
      require: true
    },
    value: {
      type: Number,
      require: true
    },
    color: {
      type: String,
      default: 'primary'
    }
  },

  methods: {
    toPage() {
      if (this.path) {
        this.$router.push({ name: this.path });
      }
    }
  }
};
</script>

<style lang="scss">
.b-card-effect {
  height: 100%;
  z-index: 4;
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  gap: 1.5rem;
  padding: 12px;
  position: relative;
  border-radius: 5px;

  &__content {
    display: flex;
    flex-direction: column;

    &--title {
      font-weight: 300;
      font-size: 0.8rem;
    }

    &--description {
      font-weight: 400;
      font-size: 1.5rem;
    }
  }

  &--icon {
    align-items: center;
    align-self: center;
    display: inline-flex;
    justify-content: center;
    border-radius: 100%;
    position: relative;
    height: 52px;
    width: 52px;
    min-width: 52px;
    transition-duration: 0.28s;
    transition-property: box-shadow, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    white-space: nowrap;

    &:before {
      background-color: currentColor;
      bottom: 0;
      border-radius: inherit;
      content: '';
      left: 0;
      opacity: 0.1;
      position: absolute;
      pointer-events: none;
      right: 0;
      top: 0;
      transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    }

    i {
      font-size: 1.3rem;
    }
  }
}

.theme--light {
  .b-card-effect {
    background-color: #fff;
    box-shadow: 0px 0px 2px 1px rgb(33, 33, 33, 0.2);

    &__content {
      &--title,
      &--description {
        color: $gray--40;
      }
    }
  }
}

.theme--dark {
  .b-card-effect {
    background-color: #363636;
    box-shadow: 0px 0px 2px 1px rgba(255, 255, 255, 0.5);

    &__content {
      &--title,
      &--description {
        color: $gray--20;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .b-card-effect {
    &--icon {
      height: 42px;
      width: 42px;
      min-width: 42px;
      i {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
